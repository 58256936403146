import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layouts'
import HomeHead from '../components/blog/HomeHead'
import PostSummary from '../components/blog/PostSummary'

export const query = graphql`
  query PostPageQuery(
    $limit: Int,
    $skip: Int
  ) {
    allPrismicPost(
      limit: $limit,
      skip: $skip,
      sort: { fields: data___date, order: DESC }
    ) {
      pageInfo {
        currentPage
        hasNextPage
        perPage
        pageCount
        itemCount
        hasPreviousPage
        totalCount
      }
      edges {
        node {
          id
          uid
          type
          tags
          data {
            title {
              richText
            }
            date
            body {
              ... on PrismicPostDataBodyText {
                id
                slice_label
                slice_type
                primary {
                  text {
                    richText
                  }
                }
              }
              ... on PrismicPostDataBodyMarkdown {
                id
                slice_label
                slice_type
                primary {
                  markdown {
                    richText
                  }
                }
              }
              ... on PrismicPostDataBodyHtml {
                id
                slice_label
                slice_type
                primary {
                  html
                }
              }
              # ... on PrismicPostDataBodyEmbed {
              #   id
              #   slice_label
              #   slice_type
              #   primary {
              #     url {
              #       embed_url
              #     }
              #   }
              # }
            }
          }
        }
      }
    }
  }
`

const Paginate = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px 0;

  a {
    display: inline-block;
    padding: .8em 1em;
    color: #666;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #ddd;
    border-radius: 4px;

    &:hover {
      color: #aaa;
    }
  }
`

const Paginator = ({ pageInfo }) => {
  if (!pageInfo) return null

  return (
    <Paginate>
      {
        pageInfo.hasPreviousPage
          ? <Link to={`/posts/${pageInfo.currentPage - 1}`}>← Prev</Link>
          : null
      }
      {
        pageInfo.hasNextPage
          ? <Link to={`/posts/${pageInfo.currentPage + 1}`}>Next →</Link>
          : null
      }
    </Paginate>
  )
}

const Posts = ({ posts }) => {
  if (!posts) return null

  return posts.map((post) => <PostSummary post={post} key={post.node.id} />)
}

const Page = ({ data }) => {
  if (!data) return null

  const { edges, pageInfo } = data.allPrismicPost

  const title = pageInfo.currentPage === 1 ? 'blog' : `blog(${pageInfo.currentPage})`

  return (
    <Layout noIndex title={title}>
      <HomeHead url="/" />
      <Posts posts={edges} />
      <Paginator pageInfo={pageInfo} />
    </Layout>
  )
}

export default Page
